import React from "react";
import FifthPageIntroSection from "./FifthPageIntroSection";
import Statistics from "./Statistics";
import Information from "./Information";
import Poster from "./Poster";

function FifthPage(props) {
  const showseventhpage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    props.updateFirstPage(false);
    props.updateFifthPage(false);
    props.updateSeventhPage(true);
  };
  const showfifthpage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    props.updateFirstPage(false);
    props.updateFifthPage(true);
  };
  return (
    <div>
      <FifthPageIntroSection showseventhpage={showseventhpage} showfifthpage={showfifthpage}/>
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "3px solid #6d123f",
        }}
      ></div>
      <Statistics />
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "3px solid #6d123f",
        }}
      ></div>
      <Poster />
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "3px solid #6d123f",
        }}
      ></div>
      <Information
        details={[
          "Store Pet Information Securely.",
          "No need to worry about losing papers",
          "Connects With Same Minded People.",
          "Access Doorstep Services.",
        ]}
        imgsources={[
          "images/fifth-page/intro/first.png",
          "images/fifth-page/intro/second.png",
          "images/fifth-page/intro/third.png",
        ]}
        buttons={[
          { name: "Become a part", functionality: showseventhpage },
          { name: "Explore Us", functionality: showfifthpage },
        ]}
      />
    </div>
  );
}

export default FifthPage;
