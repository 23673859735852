import React, { useEffect, useState } from "react";
import Navbar from "./Nav";
import FirstPage from "./FirstPage";
import Footer from "./Footer";
import Copyright from "./Copyright";
import IntroductionVideo from "./IntroductionVideo";
import SecondPage from "./SecondPage";
import ThirdPage from "./ThirdPage";
import FourthPage from "./FourthPage";
import FifthPage from "./FifthPage";
import SixthPage from "./SixthPage";
import SeventhPage from "./SeventhPage";
import CommingSoon from "./ComingSoon";
import SignUp from "./SignUp";
import Post from "./Post";
import ChatBox from "./ChatBox";
import ProfileSection from "./ProfileSection";
import FriendsSection from "./FriendsSection";

import "./app.css";

function App() {
  const [showintroductionvideo, updateIntroductionvideostate] = useState(false);
  const [posts, updatePosts] = useState([]);
  const [userData, updateUserData] = useState("");
  const [userAuthenticated, updateUserAuthenticated] = useState(false);
  const [latestpost, updateLatestPost] = useState([]);
  const [suggestedFriend, updateSuggestion] = useState([]);
  const [pendingFriend, updatedPendingFriend] = useState([]);
  const [requestedFriend, updatedRequestedFriend] = useState([]);
  const [friends, updatedFriend] = useState([]);
  const [stories, updateStories] = useState([]);
  useEffect(() => {
    setTimeout(()=>{
      updateIntroductionvideostate(true);
    }, 20000);
    fetch("https://getapi.petdiary.in", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        updatePosts(data.diaries);
        updateUserData(data.user);
        updateUserAuthenticated(data.userAuthenticated);
        setTimeout(()=>{
          updateSignUp(data.userAuthenticated);
        }, 4000);
        updateSuggestion(data.suggestedFriends);
        updatedPendingFriend(data.pendingRequests);
        updatedRequestedFriend(data.requestedFriends);
        updatedFriend(data.friends);
        updateStories(data.user.stories);
        if (data.diaries.length >= 3) {
          const latestposts = [];
          for (
            var i = data.diaries.length - 1;
            i >= data.diaries.length - 3;
            i--
          ) {
            latestposts.push(data.diaries[i]);
          }
          updateLatestPost(latestposts);
        } else {
          updateLatestPost(data.diaries);
        }
      });
  }, []);
  const [showsinglediary, updateSingleDiaryState] = useState(false);
  const [showfirstpage, updateFirstPage] = useState(true);
  const [showsecondpage, updateSecondPage] = useState(false);
  const [showthirdpage, updateThirdPage] = useState(false);
  const [showfourthpage, updateFourthPage] = useState(false);
  const [showfifthpage, updateFifthPage] = useState(false);
  const [showsixthpage, updateSixthPage] = useState(false);
  const [showseventhpage, updateSeventhPage] = useState(false);
  const [showcommingsoon, updateCommingSoon] = useState(false);
  const [showsignup, updateSignUp] = useState();
  const [addpost, updateAddPost] = useState(false);
  const [showchatbox, updateChatBox] = useState(false);
  const [showprofile, updateProfile] = useState(false);
  const [showedit, updateShowEdit] = useState(false);
  const [showfriendssection, updateFriendsSection] = useState(false);
  return (
    <div>
      <Navbar
        opaquebackground={showintroductionvideo || showsinglediary  || showcommingsoon}
        showfirstpage={showfirstpage}
        updateFirstPage={updateFirstPage}
        showsecondpage={showsecondpage}
        updateSecondPage={updateSecondPage}
        showthirdpage={showthirdpage}
        updateThirdPage={updateThirdPage}
        showfourthpage={showfourthpage}
        updateFourthPage={updateFourthPage}
        showfifthpage={showfifthpage}
        updateFifthPage={updateFifthPage}
        showsixthpage={showsixthpage}
        updateSixthPage={updateSixthPage}
        showseventhpage={showseventhpage}
        updateSeventhPage={updateSeventhPage}
      />
      {showfriendssection ? (
        <FriendsSection
          updateFriendsSection={updateFriendsSection}
          SuggestedFriend={suggestedFriend}
          pendingFriendsData={pendingFriend}
          friendsData={friends}
          requestedFriendsData={requestedFriend}
          updateCommingSoon={updateCommingSoon}
        />
      ) : undefined}
      {(showsecondpage || showthirdpage || showfourthpage) &&
      userAuthenticated ? (
        <Post addpost={addpost} updateAddPost={updateAddPost} />
      ) : undefined}
      {showchatbox ? (
        <ChatBox showchatbox={showchatbox} updateChatBox={updateChatBox} />
      ) : undefined}
      {showcommingsoon ? (
        <CommingSoon
          showcomingsoon={showcommingsoon}
          updateCommingSoon={updateCommingSoon}
        />
      ) : undefined}
      {showsignup === undefined ? undefined : !showsignup ? <SignUp updateSignUp={updateSignUp} /> : undefined}
      {showprofile ? (
        <ProfileSection
          updateShowProfile={updateProfile}
          showedit={showedit}
          updateShowEdit={updateShowEdit}
          showprofile={showprofile}
          userData={userData}
        />
      ) : undefined}
      {showfirstpage ? (
        <FirstPage
          showintroductionvideo={showintroductionvideo}
          showsinglediary={showsinglediary}
          updateSingleDiaryState={updateSingleDiaryState}
          updateFirstPage={updateFirstPage}
          updateSixthPage={updateSixthPage}
          updateFifthPage={updateFifthPage}
          isUserAuthenticated={userAuthenticated}
          showSignUp={showsignup}
          updateSignUp={updateSignUp}
          latestposts={latestpost}
          userData={userData}
          opaquebackground={showintroductionvideo || showsinglediary  || showcommingsoon}
        />
      ) : undefined}
      {showsecondpage ? (
        <SecondPage
          isUserAuthenticated={userAuthenticated}
          updateSignUp={updateSignUp}
          showchatbox={showchatbox}
          updateChatBox={updateChatBox}
          posts={posts}
          userData={userData}
          updateFriendsSection={updateFriendsSection}
          stories={stories}
          friends={friends}
          updateCommingSoon={updateCommingSoon}
          opaquebackground={showcommingsoon}
        />
      ) : undefined}
      {showthirdpage ? (
        <ThirdPage
          isUserAuthenticated={userAuthenticated}
          updateSignUp={updateSignUp}
          // userPosts={userPosts}
          userData={userData}
          updateProfile={updateProfile}
          updateFriendsSection={updateFriendsSection}
          friends={friends}
          updateCommingSoon={updateCommingSoon}
          opaquebackground={showcommingsoon}
        />
      ) : undefined}
      {showfourthpage ? (
        <FourthPage
          showcomingsoon={showcommingsoon}
          updateCommingSoon={updateCommingSoon}
          isUserAuthenticated={userAuthenticated}
          updateSignUp={updateSignUp}
          userData={userData}
          opaquebackground={showcommingsoon}
        />
      ) : undefined}
      {showfifthpage ? (
        <FifthPage
          updateFirstPage={updateFirstPage}
          updateFifthPage={updateFifthPage}
          updateSeventhPage={updateSeventhPage}
          opaquebackground={showcommingsoon}
        />
      ) : undefined}
      {showsixthpage ? <SixthPage /> : undefined}
      {showseventhpage ? (
        <SeventhPage
          showcomingsoon={showcommingsoon}
          updateCommingSoon={updateCommingSoon}
          opaquebackground={showcommingsoon}
        />
      ) : undefined}
      {showintroductionvideo ? (
        <IntroductionVideo
          showintroductionvideo={showintroductionvideo}
          updateIntroductionVideo={updateIntroductionvideostate}
          updateFifthPage={updateFifthPage}
          updateSignUp={updateSignUp}
          updateSixthPage={updateSixthPage}
          updateFirstPage={updateFirstPage}
        />
      ) : undefined}
      <Footer
        showfirstpage={showfirstpage}
        updateFirstPage={updateFirstPage}
        showsecondpage={showsecondpage}
        updateSecondPage={updateSecondPage}
        showthirdpage={showthirdpage}
        updateThirdPage={updateThirdPage}
        showfourthpage={showfourthpage}
        updateFourthPage={updateFourthPage}
        showfifthpage={showfifthpage}
        updateFifthPage={updateFifthPage}
        showsixthpage={showsixthpage}
        updateSixthPage={updateSixthPage}
        showseventhpage={showseventhpage}
        updateSeventhPage={updateSeventhPage}
      />
      <Copyright />
    </div>
  );
}

export default App;
