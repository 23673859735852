import React from "react";
import "./pet-info.css";

function PetInfo(props) {
  return (
    <div className="pet-information">
      <div className="info">
        <p>
          {props.description === "" ? "Luna the Playful Cat 🐾 Luna, the mischievous tabby, loves chasing laser pointers and napping in sunbeams.\n 🌞🐱 Feel free to customize the name, pet type, and details as needed! 😊" : props.description}
        </p>
      </div>
      <div className="img-section">
        <img src={props.img === "" ? "images/first-page/first-img.png" : props.img} alt="first-img"/>
      </div>
    </div>
  );
}

export default PetInfo;