import React from "react";
import "./lock.css";

function Lock(props) {
  return (
    <div
      className="lock"
      style={
        props.thirdpage || props.fourthpage
          ? {
              position: "static",
              height: "90vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }
          : undefined
      }
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        props.updateSignUp(false);
      }}
    >
      <img src="images/buttons/lock.png" alt="lock" />
    </div>
  );
}

export default Lock;
