import React from "react";
import "./footer.css";

function Footer(props) {
  return (
    <div>
      <div className="footer">
        <div className="info-section">
          <p>
            PetDiary offers various pet services and supplies accessible through
            a simple digital interface. It also aims to build a community of pet
            lovers worldwide.
          </p>
        </div>
        <div className="logo-section">
          <div className="image-section">
            <img src="images/logo/pet-diary.png" alt="logo" />
          </div>

          <div className="social-icons">
            <button>
              <img
                src="images/buttons/insta-button.png"
                alt="instagram-button"
              />
            </button>
            <button>
              <img
                src="images/buttons/facebook-button.png"
                alt="facebook-button"
              />
            </button>
            <button>
              <img
                src="images/buttons/twitter-button.png"
                alt="twitter-button"
              />
            </button>
          </div>
        </div>
        <div className="items-section">
          <div className="item">
            <li onClick={()=>{
               props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateFirstPage(true);
              window.scrollTo(0,0);
            }}>Home</li>
          </div>
          <div className="item">
            <li onClick={()=>{
              props.updateFirstPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateSecondPage(true);
              window.scrollTo(0,0);
            }}>Diaries</li>
          </div>
          <div className="item">
            <li onClick={()=>{
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateThirdPage(true);
              window.scrollTo(0,0);
            }}>Your Diary</li>
          </div>
          <div className="item">
            <li onClick={()=>{
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateFourthPage(true);
              props.updateThirdPage(false);
              window.scrollTo(0,0);
            }}>Your Pet</li>
          </div>
          <div className="item">
            <li onClick={()=>{
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateFifthPage(true);
              window.scrollTo(0,0);
            }}>Our Services</li>
          </div>
          <div className="item">
            <li onClick={()=>{
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateSeventhPage(false);
              props.updateSixthPage(true);
              props.updateFifthPage(false);
              window.scrollTo(0,0);
            }}>About Us</li>
          </div>
          <div className="item">
            <li onClick={()=>{
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateSeventhPage(true);
              props.updateSixthPage(false);
              props.updateFifthPage(false);
              window.scrollTo(0,0);
            }}>Be a Part</li>
          </div>
        </div>
      </div>
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "1px solid #6d123f",
        }}
      ></div>
    </div>
  );
}

export default Footer;
