import React from "react";
import "./copyright.css";

function Copyright() {
  return (
    <div className="copyright-section">
      <h4>PET DIARY</h4>
      <p>© Copyright 2023</p>
    </div>
  );
}

export default Copyright;
