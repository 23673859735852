import React from "react";
import "./sign-up.css";

function SignUp(props) {
  const googleSignUp = () => {
    window.location = "https://getapi.petdiary.in/auth/google";
  };
  const facebookSignUp = () => {
    window.location = "https://getapi.petdiary.in/auth/facebook";
  };
  return (
    <div className="sign-up">
      <button type="button" onClick={() => props.updateSignUp(true)}>
        <img src="images/buttons/wrong.png" alt="wrong" />
      </button>
      <div className="button" onClick={googleSignUp}>
        <img
          src="images/buttons/google-sign-in.png"
          alt="sign-up-with-facebook"
        />
        <h3>Sign Up with google</h3>
      </div>
      <div className="button" onClick={facebookSignUp}>
        <img
          src="images/buttons/facebook-sign-in.png"
          alt="sign-up-with-facebook"
        />
        <h3>Sign Up with facebook</h3>
      </div>
    </div>
  );
}

export default SignUp;
