import React from "react";
import "./profile.css";

function UserProfile(props) {
  return (
    <div className="user-profile">
      <div className="image-details">
        <label htmlFor="upload-img">
          {props.profiledetails.landscapeProfile === "" ? (
            <img
              src="images/third-page/upload-img-button.png"
              alt=""
              style={
                window.innerWidth < "430" ? { width: "100%" } : { width: "50%" }
              }
            />
          ) : (
            <img src={props.profiledetails.landscapeProfile} alt="" />
          )}
        </label>
        {/* <input
          type="file"
          id="upload-img"
          title="upload-img"
          onChange={(event) => {
            console.log(event.target.files[0].path);
            props.updateProfileDetails(event.target.files[0].path);
          }}
        /> */}
      </div>
      <div
        className="profile-img"
        style={window.innerWidth < "500" ? { gridColumn: "span 2" } : undefined}
      >
        <label htmlFor="upload-profile">
          {props.profiledetails.portraitProfile === "" ? (
            <img
              src="images/third-page/profile-upload-img.png"
              alt=""
              style={{ border: "3px solid #6d123f" }}
            />
          ) : (
            <img style={{borderRadius:"100%"}} src={props.profiledetails.portraitProfile} alt="" />
          )}
        </label>
        {/* <input type="file" id="upload-profile" /> */}
      </div>
      <div
        className="profile-details"
        style={window.innerWidth < "500" ? { gridColumn: "span 2" } : undefined}
      >
        <h3>{props.profiledetails.name}</h3>
        <p>
          {props.profiledetails.description}
        </p>
      </div>
      <div className="buttons-container">
        <button onClick={()=>{
          window.scrollTo({top: 0, behavior: 'smooth'});
          props.updateProfile(true)}}>Edit Your Profile</button>
        <button>Share Your Profile</button>
      </div>
    </div>
  );
}

export default UserProfile;
