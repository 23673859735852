import React from "react";
import Stories from "./Stories";
import Diaries from "./Diaries";
import Lock from "./Lock";

function SecondPage(props) {
  const profilePic = props.userData.portraitProfile;
  return (
    <div style={props.opaquebackground ? {opacity:"0.5",  position: "relative"} : { position: "relative", opacity:"1"}}>
      {props.isUserAuthenticated ? undefined : (
        <Lock updateSignUp={props.updateSignUp} />
      )}
      <Stories profilePic={profilePic} stories={props.stories} friends={props.friends}/>
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderBottom: "3px solid #6d123f",
        }}
      ></div>
      <Diaries
        showchatbox={props.showChatBox}
        updateChatBox={props.updateChatBox}
        posts={props.posts}
        likedPosts={props.userData === "" ? false : props.userData.likedPosts}
        updateFriendsSection={props.updateFriendsSection}
        friends={props.friends}
        updateCommingSoon={props.updateCommingSoon}
      />
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderBottom: "3px solid #6d123f",
        }}
      ></div>
    </div>
  );
}

export default SecondPage;
