import React from "react";
import "./friend-section.css";

const sendFriendRequest = (value) => {
  const userId = value;
  console.log(userId);
  fetch("https://getapi.petdiary.in/send/friend", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId: userId }),
    credentials: "include",
  }).then((res) => {
    console.log(res.status);
    if (res.status === 200) {
      alert("Friend Request Sent");
      window.location.reload();
    } else if (res.status === 400) {
      alert("Friend Request failed");
      window.location.reload();
    }
  });
};

const acceptFriendRequest = (value) => {
  const userId = value;
  fetch("https://getapi.petdiary.in/accept/friend", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId: userId }),
    credentials: "include",
  }).then((res) => {
    console.log(res.status);
    if (res.status === 200) {
      alert("Friend Request accepted");
      window.location.reload();
    } else if (res.status === 400) {
      alert("Friend Request failed");
      window.location.reload();
    }
  });
};

const declineFriendRequest = (value) => {
  const userId = value;
  fetch("https://getapi.petdiary.in/decline/friend", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId: userId }),
    credentials: "include",
  }).then((res) => {
    console.log(res.status);
    if (res.status === 200) {
      alert("Friend Request declined");
      window.location.reload();
    } else if (res.status === 400) {
      alert("Friend Request declined failed");
      window.location.reload();
    }
  });
};

const removeFriendRequest = (value) => {
  const userId = value;
  fetch("https://getapi.petdiary.in/remove/friend", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId: userId }),
    credentials: "include",
  }).then((res) => {
    console.log(res.status);
    if (res.status === 200) {
      alert("friend successfully removed");
      window.location.reload();
    } else if (res.status === 400) {
      alert("removing friend failed");
      window.location.reload();
    }
  });
};

const cancelFriendRequest = (value) => {
  const userId = value;
  fetch("https://getapi.petdiary.in/cancel/friend", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId: userId }),
    credentials: "include",
  }).then((res) => {
    console.log(res.status);
    if (res.status === 200) {
      alert("friend request cancelled");
      window.location.reload();
    } else if (res.status === 400) {
      alert("cancelling friend request failed");
      window.location.reload();
    }
  });
};

function PendingFriend(props) {
  return (
    <div className="pending-friend friend">
      <img src={props.profile} alt="Friend" />
      <p>{props.name}</p>
      <div className="button-sections">
        <button type="button" onClick={() => acceptFriendRequest(props.id)}>
          Accept
        </button>
        <button type="button" onClick={() => declineFriendRequest(props.id)}>
          Decline
        </button>
      </div>
    </div>
  );
}

function SuggestedFriend(props) {
  return (
    <div className="friend">
      <img src={props.profile} alt="Friend" />
      <p>{props.name}</p>
      {props.showCancel ? (
        <button
          style={{ background: "none", border: "none" }}
          onClick={() => {
            cancelFriendRequest(props.id);
          }}
        >
          <img src="images/buttons/cancel.png" alt="cancel-friendrequest" />
        </button>
      ) : (
        <button
          onClick={() => {
            sendFriendRequest(props.id);
          }}
        >
          <img src="images/buttons/add-friend.png" alt="add-friend" />
        </button>
      )}
    </div>
  );
}

function Friend(props) {
  return (
    <div className="friend friend-section">
      <img src={props.profile} alt="Friend" />
      <p>{props.name}</p>
      <div className="buttons-container">
      <button type="button" onClick={()=>{
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        props.updateCommingSoon(true);
        props.updateFriendsSection(false);
      }}>Chat</button>
      <button type="button" onClick={() => removeFriendRequest(props.id)}>
        remove
      </button>
      </div>
      
    </div>
  );
}

function FriendsSection(props) {
  return (
    <div className="friends-section">
      <button
        className="close-friends-section"
        onClick={() => props.updateFriendsSection(false)}
      >
        <img src="images/buttons/wrong.png" alt="close" />
      </button>
      <div>
         <div className="accepted-friend-request">
          {props.friendsData.length !== 0 ? <h3>Friends</h3> : undefined}
          {props.friendsData
            ? props.friendsData.map((friend, index) => {
                return (
                  <Friend
                    key={index}
                    name={friend.name}
                    profile={friend.portraitProfile}
                    id={friend.id}
                    updateCommingSoon={props.updateCommingSoon}
                    updateFriendsSection={props.updateFriendsSection}
                  />
                );
              })
            : undefined}
        </div>
        <div className="pending-friend-request">
          {props.pendingFriendsData.length !== 0 ? <h3>Pending Friends</h3> : undefined}
          {props.pendingFriendsData
            ? props.pendingFriendsData.map((friend, index) => {
                return (
                  <PendingFriend
                    key={index}
                    name={friend.name}
                    profile={friend.portraitProfile}
                    id={friend.id}
                  />
                );
              })
            : undefined}
        </div>
        <div className="suggested-friends">
          {props.SuggestedFriend.length !== 0 ? <h3>Suggested Friend</h3> : undefined}
          {props.requestedFriendsData
            ? props.requestedFriendsData.map((friend, index) => {
                return (
                  <SuggestedFriend
                    key={index}
                    name={friend.name}
                    profile={friend.portraitProfile}
                    id={friend.id}
                    showCancel={true}
                  />
                );
              })
            : undefined}
          {props.SuggestedFriend
            ? props.SuggestedFriend.map((friend, index) => {
                return (
                  <SuggestedFriend
                    key={index}
                    name={friend.name}
                    profile={friend.portraitProfile}
                    id={friend.id}
                  />
                );
              })
            : undefined}
        </div>
      </div>
    </div>
  );
}

export default FriendsSection;
