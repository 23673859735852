import React, { useState } from "react";
import "./nav.css";

function Items(props) {
  if (props.mobilenavbar) {
    return (
      <div className="items">
        <div className="item">
          <li
            onClick={() => {
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateFirstPage(true);
              props.updateToggleState(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Home
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateSecondPage(true);
              props.updateToggleState(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Diaries
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateThirdPage(true);
              props.updateToggleState(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Your Diary
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateFourthPage(true);
              props.updateThirdPage(false);
              props.updateToggleState(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Your Pet
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateFifthPage(true);
              props.updateToggleState(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Our Services
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateSeventhPage(false);
              props.updateSixthPage(true);
              props.updateFifthPage(false);
              props.updateToggleState(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            About Us
          </li>
        </div>
        <div className="item">
          <li
           onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateSeventhPage(true);
              props.updateSixthPage(false);
              props.updateFifthPage(false);
              props.updateToggleState(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Be A Part
          </li>
        </div>
      </div>
    );
  } else {
    return (
      <div className="items">
        <div className="item">
          <li
            onClick={() => {
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateFirstPage(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Home
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateSecondPage(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Diaries
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateThirdPage(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Your Diary
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              props.updateFourthPage(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Your Pet
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(true);
              props.updateSixthPage(false);
              props.updateSeventhPage(false);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Our Services
          </li>
        </div>
        <div className="item">
          <li
            onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(true);
              props.updateSeventhPage(false);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            About Us
          </li>
        </div>
        <div className="item">
          <li
           onClick={() => {
              props.updateFirstPage(false);
              props.updateSecondPage(false);
              props.updateThirdPage(false);
              props.updateFourthPage(false);
              props.updateFifthPage(false);
              props.updateSixthPage(false);
              props.updateSeventhPage(true);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Be A Part
          </li>
        </div>
      </div>
    );
  }
}

function MobileNavbar(props) {
  const [showtoggle, updateToggleState] = useState(true);
  return (
    <div
      className="mobile-navbar"
      id="navbar"
      style={props.opaquebackground ? { opacity: "0.5" } : undefined}
    >
      <div className="logo-item">
        <img src="images/logo/pet-diary.png" alt="logo"/>
      </div>
      {showtoggle ? (
        <div
          className="toggle-button"
          onClick={() => updateToggleState(!showtoggle)}
        >
          <button>
            <img src="images/logo/toggle-button.png" alt="toggle"/>
          </button>
        </div>
      ) : (
        <div
          className="toggle-button"
          onClick={() => updateToggleState(!showtoggle)}
        >
          <button>
            <img src="images/logo/wrong.png" alt="cancel"/>
          </button>
        </div>
      )}
      {!showtoggle ? (
        <Items
          mobilenavbar={true}
          showfirstpage={props.showfirstpage}
          updateFirstPage={props.updateFirstPage}
          showsecondpage={props.showsecondpage}
          updateSecondPage={props.updateSecondPage}
          showthirdpage={props.showthirdpage}
          updateThirdPage={props.updateThirdPage}
          showfourthpage={props.showfourthpage}
          updateFourthPage={props.updateFourthPage}
          showfifthpage={props.showfifthpage}
          updateFifthPage={props.updateFifthPage}
          showsixthpage={props.showsixthpage}
          updateSixthPage={props.updateSixthPage}
          showseventhpage={props.showseventhpage}
          updateSeventhPage={props.updateSeventhPage}
          updateToggleState={updateToggleState}
        />
      ) : undefined}
    </div>
  );
}

function LaptopNavbar(props) {
  return (
    <div
      className="laptop-navbar"
      style={props.opaquebackground ? { opacity: "0.5" } : undefined}
    >
      <div className="logo-item">
        <img src="images/logo/pet-diary.png" alt="logo" />
      </div>
      {
        <Items
          mobilenavbar={false}
          showfirstpage={props.showfirstpage}
          updateFirstPage={props.updateFirstPage}
          showsecondpage={props.showsecondpage}
          updateSecondPage={props.updateSecondPage}
          showthirdpage={props.showthirdpage}
          updateThirdPage={props.updateThirdPage}
          showfourthpage={props.showfourthpage}
          updateFourthPage={props.updateFourthPage}
          showfifthpage={props.showfifthpage}
          updateFifthPage={props.updateFifthPage}
          showsixthpage={props.showsixthpage}
          updateSixthPage={props.updateSixthPage}
          showseventhpage={props.showseventhpage}
          updateSeventhPage={props.updateSeventhPage}
        />
      }
    </div>
  );
}

function Navbar(props) {
  return window.innerWidth < 768 ? (
    <MobileNavbar
      opaquebackground={props.opaquebackground}
      showfirstpage={props.showfirstpage}
      updateFirstPage={props.updateFirstPage}
      showsecondpage={props.showsecondpage}
      updateSecondPage={props.updateSecondPage}
      showthirdpage={props.showthirdpage}
      updateThirdPage={props.updateThirdPage}
      showfourthpage={props.showfourthpage}
      updateFourthPage={props.updateFourthPage}
      showfifthpage={props.showfifthpage}
      updateFifthPage={props.updateFifthPage}
      showsixthpage={props.showsixthpage}
      updateSixthPage={props.updateSixthPage}
      showseventhpage={props.showseventhpage}
      updateSeventhPage={props.updateSeventhPage}
    />
  ) : (
    <LaptopNavbar
      opaquebackground={props.opaquebackground}
      showfirstpage={props.showfirstpage}
      updateFirstPage={props.updateFirstPage}
      showsecondpage={props.showsecondpage}
      updateSecondPage={props.updateSecondPage}
      showthirdpage={props.showthirdpage}
      updateThirdPage={props.updateThirdPage}
      showfourthpage={props.showfourthpage}
      updateFourthPage={props.updateFourthPage}
      showfifthpage={props.showfifthpage}
      updateFifthPage={props.updateFifthPage}
      showsixthpage={props.showsixthpage}
      updateSixthPage={props.updateSixthPage}
      showseventhpage={props.showseventhpage}
      updateSeventhPage={props.updateSeventhPage}
    />
  );
}

export default Navbar;
