import React, { useState } from "react";
import "./story.css";

let timeout = "";

function switch_image(pos, length, updateImg, images, updateSmallSizeStoryState){
timeout = setTimeout(()=>{
  if(pos < length){
     updateImg(images[pos]);
     switch_image(pos+1, length, updateImg, images, updateSmallSizeStoryState);
  }
  else{
    clearTimeout(timeout);
    updateImg("");
    updateSmallSizeStoryState(true);
  }
},5000)
}

function Story(props) {
  const [showsmallsizeStory, updateSmallSizeStoryState] = useState(true);
  const [showImageUpload, updateImageUpload] = useState(false);
  const [showimg, updateImgState] = useState(""); 
  const [img, updateImg] = useState("");
  const [showloading, updateLoading] = useState(false);
  
  const uploadStory = ()=>{
    updateLoading(true);
    if(showimg !== ""){
      const formData = new FormData();
      formData.append("file", showimg.file);
      fetch("https://getapi.petdiary.in/upload/story", {
        method: "POST",
        body: formData,
        credentials: "include",
      })
        .then((response) => {
          if(response.status === 200){
            alert("Image uploaded successfully");
            window.location.reload();
          }
          else{
            alert("Image upload failed");
            window.location.reload();
          }
        }).catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  return (
    <div className="story">
      {showsmallsizeStory ? (
        <div className="small-story-container">
          {props.showaddicon ? (
            <button
              onClick={() => {
                updateImageUpload(true);
                updateSmallSizeStoryState(false);
              }}
              className="add-icon"
            >
              <img src="images/buttons/add.png" alt="add-icon" />
            </button>
          ) : undefined}
          <button
            onClick={() => {
              if(props.stories.length === 0){
                alert("No stories to show");
              }
              else{
                updateSmallSizeStoryState(false);
                switch_image(1, props.stories.length, updateImg, props.stories, updateSmallSizeStoryState);
              }
              }}
          >
            <img src={props.profilePic} alt="story" />
          </button>
        </div>
      ) : (
        <div className="big-story-container">
          <div className="button-container">
            {showImageUpload ? (
              <button
                onClick={() => {
                  updateImageUpload(false);
                  updateSmallSizeStoryState(true);
                  clearTimeout(timeout);
                }}
              >
                <img src="images/buttons/wrong.png" alt="wrong" />
              </button>
            ) : (
              <button onClick={() => updateSmallSizeStoryState(true)}>
                <img src="images/buttons/wrong.png" alt="wrong" />
              </button>
            )}
          </div>

          {showImageUpload ? (
            <div className="upload-img-section">
              {showimg !== "" ? (
                <div className="img-content">
                  <img
                    style={{ cursor: "pointer" }}
                    src={showimg.img}
                    alt=""
                    onClick={() => updateImgState("")}
                  />
                </div>
              ) : undefined}

              <label htmlFor="img-upload">
                <img src="images/buttons/insert-image.png" alt="upload" />
                Upload Your File
              </label>
              <input
                onChange={(event) => {
                  const files = event.target.files[0];
                  updateImgState({
                    img: URL.createObjectURL(files),
                    file: event.target.files[0],
                  });
                }}
                id="img-upload"
                type="file"
                accept="image/png, image/jpeg"
              />
              <button onClick={uploadStory} disabled={showloading}>Submit</button>
            </div>
          ) : (
            <div className="image-container">
              {props.stories ? <img src={img === "" ? props.stories[0] : img} alt=""/> : undefined}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Story;
