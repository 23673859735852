import React, { useState } from "react";
import Diary from "./Diary";
import "./diaries.css";
import Avatar from "./Avatar";

function Diaries(props) {
  const widthOfScreen = window.innerWidth;
  const [showfriendslist, updateFriendsList] = useState(false);
  return (
    <div className="diaries-container">
      <div className="diaries">
        {props.posts.map((diary, index) => (
          <Diary
            key={index}
            name={diary.name}
            profile={diary.profilePic}
            value={diary._id}
            trendingdiary={false}
            content={diary.content}
            comments={diary.comments}
            likes={diary.likes}
            likedPosts={props.likedPosts}
            imgurl={diary.imgUrl}
          />
        ))}
      </div>
      {widthOfScreen < 463 ? (
        <div className="mobile-friends-list" id="mobile-friend-list">
          <button
            className="toggle"
            onClick={() => {
              document
                .getElementById("mobile-friend-list")
                .scrollIntoView({ behavior: "smooth" });
              updateFriendsList(!showfriendslist);
            }}
          >
            <img src="images/logo/friend-toggle.png" alt="" />
          </button>
          {showfriendslist ? (
            <div className="friends-list">
              <div className="friends">
                {props.friends.map((friend, index) => (
                  <Avatar
                    key={index}
                    profile={friend.portraitProfile}
                    updateCommingSoon={props.updateCommingSoon}
                  />
                ))}
              </div>
              <div className="button-container">
                <button onClick={() => props.updateFriendsSection(true)}>
                  Add Your Friend
                </button>
              </div>
            </div>
          ) : undefined}
        </div>
      ) : (
        <div className="laptop-friends-list">
          <div>
            <div className="friends">
              {props.friends.map((friend, index) => (
                <Avatar
                  key={index}
                  profile={friend.portraitProfile}
                  updateCommingSoon={props.updateCommingSoon}
                />
              ))}
            </div>
            <div className="button-container">
              <button onClick={() => props.updateFriendsSection(true)}>
                Add Your Friend
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Diaries;
