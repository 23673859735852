import React from "react";
import "./information-section.css";
import CurveImage from "./CurveImage";

function Information(props) {
  return (
    <div
      className="information-container"
      id="information"
      style={props.opaquebackground ? { opacity: "0.5" } : undefined}
    >
      <h3>What we do?</h3>
      <div className="information-section">
        <div className="detail-section">
          <div className="line-section">
            <div className="line-drawer"></div>
            <div className="line-drawer"></div>
            <div className="line-drawer"></div>
            <div className="line-drawer"></div>
          </div>
          <div className="details">
            {props.details.map((detail,index) => <p key={index}>{detail}</p>)}
          </div>
        </div>
        <div className="image-section">
          {props.imgsources.map((imgsource,index) =><CurveImage key={index} source={imgsource}/>)}
        </div>
      </div>
      <div className="button-section">
        {props.buttons.map((button,index) => <button key={index} type="button" onClick={()=>button.functionality()}>{button.name}</button>)}
      </div>
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderBottom: "3px solid #6d123f",
        }}
      ></div>
    </div>
  );
}

export default Information;
