import React from "react";
import "./avatar.css";

function Avatar(props){
    return(
        <div className="avatar">
            <button onClick={()=>{
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                
                });
                props.updateCommingSoon(true);
            }}>
                    <img src={props.profile} alt="friends"/>
                </button>
        </div>
    );
}

export default Avatar;