import React from "react";
import Diary from "./Diary";
import Lock from "./Lock";
import "./trending-diary.css";


function TrendingDiaries(props) {
  const trendingdiaries = props.latestposts;
  let singleDiary = [];
  if(props.showsinglediary){
    singleDiary = trendingdiaries.filter((diary) => diary._id === props.showsinglediary.postId);
  }
  return (
    <div
      className="trending-diaries"
      id="trending-diary"
      style={!props.showsinglediary? { position: "relative" } : {position:"static"}}
    >
      <div className="single-diary" style={props.showsinglediary ? {width:"100%"} : {width:"auto"}}>
        {props.showsinglediary
          ? singleDiary.map((diary, index) => (
              <Diary
                updateSingleDiaryState={props.updateSingleDiaryState}
                key={index}
                name={diary.name}
                profile={diary.profilePic}
                value={diary._id}
                trendingdiary={true}
                content={diary.content}
                comments={diary.comments}
                likes={diary.likes}
                likedPosts={props.likedPosts}
                imgurl={diary.imgUrl}
              />
            ))
          : undefined}
      </div>
      <h2>Trending</h2>
      <h3>Diaries</h3>
      <div className="diaries">
        {props.isUserAuthenticated ? undefined : (
          <Lock updateSignUp={props.updateSignUp} />
        )}
        {trendingdiaries.map((diary, index) => (
          <Diary
            key={index}
            value={diary._id}
            name={diary.name}
            profile={diary.profilePic}
            trendingdiary={true}
            content={diary.content}
            comments={diary.comments}
            likes={diary.likes}
            likedPosts={props.likedPosts}
            updateSingleDiaryState={props.updateSingleDiaryState}
            showdiary={props.showsinglediary}
            imgurl={diary.imgUrl}
          />
        ))}
      </div>
    </div>
  );
}

export default TrendingDiaries;
