import React from "react";
import "./curve-image.css";

function CurveImage(props) {
  return (
    <div className="curve-img">
      <img src={props.source} alt="" />
    </div>
  );
}

export default CurveImage;