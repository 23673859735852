import React, { useState } from "react";
import Story from "./Story";
import "./stories.css";

function Stories(props) {
  const [showaddicon, updateStory] = useState(true);
  return (
    <div className="stories">
      <Story
        showaddicon={showaddicon}
        updateStory={updateStory}
        profilePic={props.profilePic}
        stories={props.stories}
      />
      {props.friends.map((friend, index) => (
        <Story key={index} showaddicon={false} profilePic={friend.portraitProfile} stories={friend.stories} />
      ))}
    </div>
  );
}

export default Stories;
