import React, { useState } from "react";
import "./profile-section.css";

function generateUrl(url) {
  try {
    return URL.createObjectURL(url);
  } catch (TypeError) {
    return url;
  }
}

function ProfileSection(props) {
  console.log(props.userData);
  const [description, updateDescription] = useState(
    props.userData.description === undefined ? "" : props.userData.description
  );
  const [petname, updatePetName] = useState(
    props.userData.pet === undefined
      ? ""
      : props.userData.pet.name === undefined
      ? ""
      : props.userData.pet.name
  );
  const [pettype, updatePetType] = useState(
    props.userData.pet === undefined
      ? ""
      : props.userData.pet.type === undefined
      ? ""
      : props.userData.pet.type
  );
  const [landscape, updateLandscape] = useState(
    props.userData.landscapeProfile
  );
  const [portrait, updatePortrait] = useState(props.userData.portraitProfile);
  const [petphoto, updatePetPhoto] = useState(
    props.userData.pet === undefined
      ? ""
      : props.userData.pet.photo === undefined
      ? ""
      : props.userData.pet.photo
  );
  const onSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("description", description);
    formData.append("petname", petname);
    formData.append("pettype", pettype);
    formData.append("landscape", landscape);
    formData.append("portrait", portrait);
    formData.append("petPhoto", petphoto);
    fetch("https://getapi.petdiary.in/update/profile", {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          props.updateShowEdit(false);
          props.updateShowProfile(false);
          alert("Profile updated successfully");
          window.location.reload();
        } else {
          alert("Error in updating profile");
        }
      });
  };
  return (
    <div className="profile-container">
      {props.showedit || props.showprofile ? (
        <button
          title="cancel"
          className="cancel"
          type="button"
          onClick={() => {
            props.updateShowProfile(false);
            props.updateShowEdit(false);
          }}
        >
          <img src="images/buttons/wrong.png" alt="" />
        </button>
      ) : undefined}
      <h3>Your Information</h3>
      <div className="profile-section">
        <form onSubmit={onSubmit}>
          <div className="image-section">
            <div className="landscape-section">
              <label htmlFor="landscape">
                {landscape === "" ? (
                  <img src="images/buttons/insert-image.png" alt="upload" />
                ) : (
                  <img src={generateUrl(landscape)} alt="landscape" />
                )}
              </label>
              {props.showedit ?  <input
                type="file"
                id="landscape"
                name="landscape"
                accept="image/*"
                onChange={(event) => {
                  props.updateShowEdit(true);
                  updateLandscape(event.target.files[0]);
                }}
              /> : undefined}
             
            </div>
            <div className="portrait-section">
              <label htmlFor="portrait">
                {portrait === "" ? (
                  <img src="images/buttons/insert-image.png" alt="" />
                ) : (
                  <img src={generateUrl(portrait)} alt="portrait" />
                )}
              </label>
              {props.showedit ? <input
                type="file"
                id="portrait"
                name="portrait"
                accept="image/*"
                onChange={(event) => {
                  props.updateShowEdit(true);
                  updatePortrait(event.target.files[0]);
                }}
              /> : undefined}
              
            </div>
          </div>
          <div className="user-details">
            <div className="detail">
              <span>Name</span>
              <p>{props.userData.name}</p>
            </div>
            <div className="detail">
              <span>Email</span>
              <p>{props.userData.email}</p>
            </div>
            <div className="detail">
              <span>Description</span>

              {props.showedit ? (
                <div className="input-section">
                  <textarea
                    title="Description"
                    placeholder="describe yourself."
                    value={description}
                    onChange={(event) => updateDescription(event.target.value)}
                  ></textarea>
                </div>
              ) : (
                <p>{description}</p>
              )}
            </div>
            <div className="detail">
              <span>Pet Name</span>
              {props.showedit ? (
                <div className="input-section">
                  <input
                    type="text"
                    id="pet-name"
                    name="pet-name"
                    value={petname}
                    onChange={(event) => {
                      updatePetName(event.target.value);
                    }}
                  />
                </div>
              ) : (
                <p>{petname}</p>
              )}
            </div>
            <div
              className="detail"
              style={props.showedit ? { flexDirection: "column" } : undefined}
            >
              <span>Pet Type</span>
              {props.showedit ? (
                <div className="input-section checkboxes">
                  <div className="checkbox">
                    <label htmlFor="dog">
                      <img src="images/buttons/checkbox/dog.png" alt="dog" />
                    </label>
                    <input
                      type="checkbox"
                      id="dog"
                      value={pettype}
                      onChange={() => {
                        updatePetType("dog");
                      }}
                    ></input>
                  </div>
                  <div className="checkbox">
                    <label htmlFor="cat">
                      <img src="images/buttons/checkbox/cat.png" alt="cat" />
                    </label>
                    <input
                      type="checkbox"
                      id="cat"
                      value={pettype}
                      onChange={() => {
                        updatePetType("cat");
                      }}
                    ></input>
                  </div>
                  <div className="checkbox">
                    <label htmlFor="others">
                      <img
                        src="images/buttons/checkbox/other-pet.png"
                        alt="dog"
                      />
                    </label>
                    <input
                      type="checkbox"
                      id="others"
                      value={pettype}
                      onChange={() => {
                        updatePetType("others");
                      }}
                    ></input>
                  </div>
                  {pettype === "others" ? (
                    <input type="text" id="pet-type" name="pet-type" />
                  ) : undefined}
                </div>
              ) : (
                <p>{pettype}</p>
              )}
            </div>
            {props.showedit ? (
              <div
                className="upload-pet-photo detail"
                style={
                  petphoto !== "" ? { justifyContent: "center" } : undefined
                }
              >
                <label htmlFor="pet-photo">
                  {petphoto === "" ? (
                    <img
                      src="images/buttons/upload-pet-pic.png"
                      alt="upload-pet"
                    />
                  ) : (
                    <img src={generateUrl(petphoto)} alt="" />
                  )}
                </label>
                <input
                  type="file"
                  id="pet-photo"
                  name="pet-photo"
                  accept="image/*"
                  onChange={(event) => {
                    updatePetPhoto(event.target.files[0]);
                  }}
                />
              </div>
            ) : undefined}
          </div>
          {props.showedit ? <button type="submit">Submit</button> : undefined}
        </form>
        {!props.showedit ? (
          <button
            className="edit-button"
            type="button"
            onClick={() => props.updateShowEdit(true)}
          >
            Edit Profile
          </button>
        ) : undefined}
      </div>
    </div>
  );
}

export default ProfileSection;
