import React,{useState} from "react";
import "./chat-box.css";

function ChatBox(props) {
  const [messages, updateMessages] = useState([]);
  return (
    <div className="chat-box">
      <button className="close-chat-box" type="button" onClick={()=>props.updateChatBox(false)}>
        <img src="images/buttons/wrong.png" alt="close-button" />
      </button>
      <div className="chat-box-body">
        <div className="message">
          <img src="images/first-page.png" alt="profile" />
          <div className="message-text">
            <p>Lorem ipsum dolor sit amet, consectetur ipsum</p>
            <span>time:12:01</span>
          </div>
        </div>
        <div className="message">
          <img src="images/first-page.png" alt="profile" />
          <div className="message-text">
            <p>Lorem ipsum dolor sit amet, consectetur</p>
            <span>time:12:02</span>
          </div>
        </div>
        <div className="message">
          <img src="images/first-page.png" alt="profile" />
          <div className="message-text">
            <p>Lorem ipsum dolor sit amet, consectetur</p>
            <span>time:12:03</span>
          </div>
        </div>
        <div className="message">
          <img src="images/first-page.png" alt="profile" />
          <div className="message-text">
            <p>Lorem ipsum dolor sit amet, consectetur</p>
            <span>time:12:04</span>
          </div>
        </div>
        <div className="message">
          <img src="images/first-page.png" alt="profile" />
          <div className="message-text">
            <p>Lorem ipsum dolor sit amet, consectetur</p>
            <span>time:12:05</span>
          </div>
        </div>
        <div className="message">
          <img src="images/first-page.png" alt="profile" />
          <div className="message-text">
            <p>Lorem ipsum dolor sit amet, consectetur</p>
            <span>time:12:06</span>
          </div>
        </div>
        <div className="message">
          <img src="images/first-page.png" alt="profile" />
          <div className="message-text">
            <p>Lorem ipsum dolor sit amet, consectetur</p>
            <span>time:12:07</span>
          </div>
        </div>
      </div>
      <div className="chat-box-message">
        <form>
          <input type="text" value={messages} placeholder="Type a message..." onChange={(event)=>{
            updateMessages([...messages,{message:event.target.value,time:new Date().toLocaleTimeString()}]);
          }}/>
          <button type="submit">
            <img src="images/buttons/submit.png" alt="submit" />
          </button>
        </form>
      </div>
    </div>
  );
}

export default ChatBox;
