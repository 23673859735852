import React, { useState } from "react";
import "./diary.css";
import CommentBox from "./CommentBox";

function checkLikedPosts(likedPosts, uniqueId) {
  if (likedPosts) {
    if (likedPosts.includes(uniqueId)) {
      return true;
    }
  }
  return false;
}

function Diary(props) {
  const [showcommentBox, updateCommentBox] = useState(false);
  const [showloading, updateLoading] = useState(false);
  const uniqueId = props.value;
  const likedPosts = props.likedPosts;
  const disliked = checkLikedPosts(likedPosts, uniqueId);
  const handleClick = (event) => {
    const isTrendingDiary = props.trendingdiary;
    if (isTrendingDiary) {
      if (props.showdiary || props.showdiary === undefined) {
        props.updateSingleDiaryState(false);
        document.getElementById("trending-diary").scrollIntoView({
          behavior: "smooth",
        });
      } else {
        const currentId = event.currentTarget.id.slice(1);
        props.updateSingleDiaryState({
          postId: currentId
        });
        document.getElementById("welcome-section").scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };
  const likePost = () => {
     updateLoading(true);
    const postId = uniqueId;
    fetch("https://getapi.petdiary.in/post/like", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        postId: postId,
      }),
      credentials: "include",
    }).then((res) => {
      if (res.status === 200) {
        alert("Post liked successfully");
        window.location = "/";
      } else {
        alert("Failed to like post");
      }
         updateLoading(false);
    });
  };
  const dislikePost = () => {
     updateLoading(true);
    const postId = uniqueId;
    fetch("https://getapi.petdiary.in/post/dislike", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        postId: postId,
      }),
    }).then((res) => {
      if (res.status === 200) {
        console.log(res.json());
        alert("Post disliked successfully");
        window.location = "/";
      } else {
        alert("Failed to dislike post");
      }
         updateLoading(false);
    });
  };
  return (
    <div className="diary" id={uniqueId}>
      <div className="profile-section" id={"#"+uniqueId} onClick={handleClick}>
        <img src={props.profile} alt="profile" />
        <h3>{props.name}</h3>
      </div>
      <div className="detail-section">
        {showcommentBox ? (
          <CommentBox
            id={uniqueId}
            close={updateCommentBox}
            comments={props.comments}
          />
        ) : (
          <p>{props.content}</p>
        )}
      </div>
      <div className="images-section">
        {props.imgurl.map((url,index)=><img key={index} src={url} alt="" />)}
      </div>
      <div className="buttons-section">
        {disliked ? (
          <button
            type="button"
            id={"button-" + uniqueId}
            onClick={dislikePost}
            style={{
              opacity: "1",
              background: "#fcc0c5",
              display: "grid",
              gridTemplateColumns: "1fr 0.5fr",
              alignItems: "center",
              justifyContent: "center",
            }}
            disabled={showloading}
          >
            <img src="images/buttons/like-button.png" alt="like-button" />
            <span>{props.likes}</span>
          </button>
        ) : (
          <button
            type="button"
            id={"button-" + uniqueId}
            onClick={likePost}
            style={{
              opacity: "0.7",
              display: "grid",
              gridTemplateColumns: "1fr 0.5fr",
              alignItems: "center",
              justifyContent: "center",
            }}
            disabled={showloading}
          >
            <img src="images/buttons/like-button.png" alt="like-button" />
            {props.likes === 0 ? "" : <span>{props.likes}</span>}
          </button>
        )}
        <button
          type="button"
          onClick={() => {
            updateCommentBox(!showcommentBox);
            document.getElementById(uniqueId).scrollIntoView({
              behavior: "smooth",
            });
          }}
        >
          <img src="images/buttons/comment-button.png" alt="comment-button" />
        </button>
        <button type="button">
          <img src="images/buttons/share-button.png" alt="share-button" />
        </button>
      </div>
    </div>
  );
}

export default Diary;
