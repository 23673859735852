import React from "react";
import PetInfo from "./PetInfo";
import GraphicalPetInfo from "./GraphicalPetInfo";
import ServiceSection from "./ServiceSection";
import Lock from "./Lock";

function PetDetails(props) {
  let [petname, pettype, img] = ["", "", props.petData.photo === undefined ? "" : props.petData.photo];
  let description = "";
  if (props.petData !== "") {
    petname = props.petData.name === undefined ? "" : props.petData.name;
    pettype = props.petData.type === undefined ? "" : props.petData.type;
  }
  if (petname !== "" && pettype !== "") {
    description =
      "Introducing " +
      petname +
      ", the mischievous " +
      pettype +
      "! 🐶 Ready to steal your heart and fetch endless joy! 🎾🐾";
  }
  return (
    <div>
      <PetInfo description={description} img={img}/>
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "3px solid #6d123f",
        }}
      ></div>
      <GraphicalPetInfo />
    </div>
  );
}

function FourthPage(props) {
  let petData = "";
  if (props.userData !== "" && props.userData.petData !== undefined) {
    petData = props.userData.pet;
  }
  const comingSoon = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    props.updateCommingSoon(true);
  };
  return (
    <div style={props.opaquebackground ? { opacity:"0.5",position: "relative" } : {opacity:"1", position:"relative"}}>
      {props.isUserAuthenticated ? (
        <PetDetails petData={petData} />
      ) : (
        <Lock fourthpage={true} updateSignUp={props.updateSignUp} />
      )}
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderBottom: "3px solid #6d123f",
        }}
      ></div>
      <ServiceSection
        backgroundcolor="#fff8f3"
        font_color="#6d123f"
        detail=" As a Pet parent, you understand the significance of keeping your pet's
          vaccinations current in promoting their health and preventing the
          transmission of human infections."
        first_button="Vaccination Report"
        first_button_img="images/buttons/download-icon.png"
        second_button="Book Appointment"
        second_button_img="images/buttons/medicine-icon.png"
        last_section_detail=" Book your Pet vaccination from your home and receive the service at your
        preferred location for greater convenience."
        button_functionality={comingSoon}
      />
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "3px solid #6d123f",
        }}
      ></div>
      <ServiceSection
        backgroundcolor="#6d123f"
        font_color="#fff8f3"
        detail="Provide regular check-ups and veterinary care to monitor the health of pets. Offer personalized nutrition plans to ensure a balanced diet. Implement a comprehensive grooming program to keep pets clean and well-maintained."
        first_button="Book a food"
        first_button_img="images/buttons/book_food.png"
        second_button="Book a Services"
        second_button_img="images/buttons/book_service.png"
        button_background_color="#fcc0c5"
        button_font_color="#6d123f"
        button_functionality={comingSoon}
      />
    </div>
  );
}

export default FourthPage;
