import React from "react";
import UserProfile from "./Profile";
import Diaries from "./Diaries";
import Lock from "./Lock";

function PageAfterAuth(props) {
  return (
    <div>
      <UserProfile
        profiledetails={props.profiledetails}
        updateProfile={props.updateProfile}
      />
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderBottom: "3px solid #6d123f",
        }}
      ></div>
      <Diaries
        posts={props.userPosts}
        likedPosts={props.likedPosts}
        updateFriendsSection={props.updateFriendsSection}
        friends={props.friends}
        updateCommingSoon={props.updateCommingSoon}
      />
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderBottom: "3px solid #6d123f",
        }}
      ></div>
    </div>
  );
}

function ThirdPage(props) {
  let userPosts = props.userData.posts;
  return (
    <div style={props.opaquebackground ? { opacity:"0.5",position: "relative" } : {opacity:"1", position:"relative"}}>
      {props.isUserAuthenticated ? (
        <PageAfterAuth
          profiledetails={props.userData}
          userPosts={userPosts}
          updateProfile={props.updateProfile}
          likedPosts={props.userData === "" ? false : props.userData.likedPosts}
          updateFriendsSection={props.updateFriendsSection}
          friends={props.friends}
          updateCommingSoon={props.updateCommingSoon}
        />
      ) : (
        <Lock thirdpage={true} updateSignUp={props.updateSignUp} />
      )}
    </div>
  );
}

export default ThirdPage;
