import React from "react";
import "./service-section.css";

function VaccinationSection(props) {
  return (
    <div
      className="services-section"
      style={{
        backgroundColor: props.backgroundcolor,
        color: props.font_color,
      }}
    >
      <div className="information-section">
        <p>{props.detail}</p>
      </div>
      <div className="buttons-section">
        <button
          type="button"
          onClick={props.button_functionality}
          style={{
            backgroundColor: props.button_background_color,
            color: props.button_font_color,
          }}
        >
          <img src={props.first_button_img} alt="vaccination" />
          {props.first_button}
        </button>
        <button
          type="button"
          onClick={props.button_functionality}
          style={{
            backgroundColor: props.button_background_color,
            color: props.button_font_color,
          }}
        >
          <img
            src={props.second_button_img}
            alt="appointment"
            style={{ backgroundColor: "white" }}
          />{" "}
          {props.second_button}
        </button>
      </div>
      <p style={{ width: "90%" }}>{props.last_section_detail}</p>
    </div>
  );
}

export default VaccinationSection;
