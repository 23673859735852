import React from "react";
import SeventhPageIntro from "./SeventhPageIntro";
import SeventhPageSecondPart from "./SeventhPageSecondPart";
import Poster from "./Poster";

function SeventhPage(props) {
  const shopdetails = [
    "List your pet shop on our site",
    "Boost your business easily",
    "Simple and straightforward process",
    "Open to all pet-related businesses",
    "Expand your reach and grow",
  ];
  const doctorsdetails = [
    "Veterinarians can register on Pet Diary",
    "Engage for professional development",
    "Platform meets vet needs",
    "Helps care for furry patients",
    "Enhance skills and knowledge"
  ];
  const showcomingsoon = ()=>{
    window.scrollTo({ top: 0, behavior: "smooth" });
    props.updateCommingSoon(true);
  };
  return (
    <div style={props.opaquebackground ? {opacity:"0.5"} : {opacity:"1"}}>
      <SeventhPageIntro showcomingsoon={showcomingsoon}/>
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "3px solid #6d123f",
        }}
      ></div>
      <SeventhPageSecondPart
        shopdetails={shopdetails}
        buttonname="List Your Shop"
        showcomingsoon={showcomingsoon}
      />
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderBottom: "3px solid #6d123f",
        }}
      ></div>
      <SeventhPageSecondPart
        shopdetails={doctorsdetails}
        buttonname="Join Us as Doctor"
        background="#FFF8F3"
        buttonbackground="#6D123F"
        showcomingsoon={showcomingsoon}
      />
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "3px solid #6d123f",
        }}
      ></div>
      <Poster background="#6D123F"/>
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderBottom: "3px solid #6d123f",
        }}
      ></div>
    </div>
  );
}

export default SeventhPage;
