import React from "react";
import Carousel from "./Carousel";

function SixthPage() {
  return (
    <div>
      <Carousel />
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "3px solid #6d123f",
        }}
      ></div>
      <div className="video-section">
        <video
          muted={true}
          autoPlay={true}
          controls={true}
          style={{ width: "100%" }}
        >
          <source src="/videos/introduction.mp4" type="video/mp4" />
        </video>
        <div
          style={{
            height: "1px",
            background: "#FFF8F3",
            borderTop: "3px solid #6d123f",
          }}
        ></div>
      </div>
    </div>
  );
}

export default SixthPage;
