import React from "react";
import "./statistics.css";

function Statistics() {
  return (
    <div className="statistics">
      <h3>Our Statistics</h3>
      <div className="grid">
        <div className="item">
          <img src="images/fifth-page/Statistics/pet-user.png" alt="pet-user" />
          <p>Pet Users Registered</p>
        </div>
        <div className="item">
          <img src="images/fifth-page/Statistics/pet-shop.png" alt="pet-shop" />
          <p>Pet Shops</p>
        </div>
        <div className="item">
          <img
            src="images/fifth-page/Statistics/doorstep-service.png"
            alt="doorstep-service"
          />
          <p>Doorstep Service Provided</p>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
