import React, { useEffect, useState } from "react";
import "./carousel.css";

let timer = "";

function Item(props) {
  return (
    <div className="item">
      <div>
        <img src={props.firstimgpath} alt="first" />
        <img
          src={props.thirdimgpath}
          alt="third"
          style={{ borderRadius: "100%" }}
        />
      </div>
      <div>
        <h3>{props.heading}</h3>
        <img
          src="images/logo/pet-diary.png"
          alt="logo"
          style={{ borderRadius: "100%", width: "25%" }}
        />
        <p>Your Pet's Best Friend</p>
      </div>
      <div>
        <img src={props.secondimgpath} alt="second" />
        <img
          src={props.fourthimgpath}
          alt="fourth"
          style={{ borderRadius: "100%" }}
        />
      </div>
    </div>
  );
}

function Carousel() {
  const [pos, updatePos] = useState(0);
  timer = setTimeout(() => {
    if (pos === 2) {
      updatePos(0);
    } else {
      updatePos((prevvalue) => prevvalue + 1);
    }
  }, 3000);
  return (
    <div className="carousel-container">
      <div className="items">
        {pos === 0 ? (
          <Item
            firstimgpath="images/Carousel/first-slide/first.png"
            secondimgpath="images/Carousel/first-slide/second.png"
            thirdimgpath="images/Carousel/first-slide/third.png"
            fourthimgpath="images/Carousel/first-slide/fourth.png"
            heading="Share Your Diaries with Your
loved Ones!"
          />
        ) : undefined}
        {pos === 1 ? (
          <Item
            firstimgpath="images/Carousel/second-slide/first.png"
            secondimgpath="images/Carousel/second-slide/second.png"
            thirdimgpath="images/Carousel/second-slide/third.png"
            fourthimgpath="images/Carousel/second-slide/fourth.png"
            heading="Book any pet services or
Order Any Pet Products
from Your Doorstep"
          />
        ) : undefined}

        {pos === 2 ? (
          <Item
            firstimgpath="images/Carousel/third-slide/first.png"
            secondimgpath="images/Carousel/third-slide/second.png"
            thirdimgpath="images/Carousel/third-slide/third.png"
            fourthimgpath="images/Carousel/third-slide/fourth.png"
            heading="Pet services and It’s products within 24 hours at your doorstep from
          Local store"
          />
        ) : undefined}
      </div>
      <div className="button-sections">
        <button
          type="button"
          value={0}
          style={pos === 0 ? { opacity: "1" } : undefined}
          onClick={() => {
            clearTimeout(timer);            
            updatePos(0);
            timer = setTimeout(()=>{
              updatePos((prevvalue)=>prevvalue +1);
            },3000);
          }}
        ></button>
        <button
          type="button"
          value={1}
          style={pos === 1 ? { opacity: "1" } : undefined}
          onClick={() => {
            clearTimeout(timer);
            updatePos(1);
            timer = setTimeout(()=>{
              updatePos((prevvalue)=>prevvalue +1);
            },3000);
          }}
        ></button>
        <button
          type="button"
          value={2}
          style={pos === 2 ? { opacity: "1" } : undefined}
          onClick={() => {
            clearTimeout(timer);
            updatePos(2);
            timer = setTimeout(()=>{
              updatePos(0);
            },3000);
          }}
        ></button>
      </div>
    </div>
  );
}

export default Carousel;
