import React from "react";
import "./coming-soon.css";

function CommingSoon(props) {
  return (
    <div className="coming-soon">
      <div>
        <button type="button" onClick={() => props.updateCommingSoon(false)}>
          <img src="images/buttons/wrong.png" alt="close" />
        </button>
        <h3>Comming Soon</h3>
        <p>Some functionality is in development mode, will be released soon. <br/> For that time continue exploring different products.</p>
        <p>Stay tuned !</p>
      </div>
    </div>
  );
}

export default CommingSoon;
