import React from "react";
import "./poster.css";

function Poster(props){
    return (
        <div className="poster-section" style={{background:props.background}}>
      {window.innerWidth < "560" ? (
        <img src="images/posters/mobile-poster.png" alt="mobile-poster"/>
      ) : (
        <img src="images/posters/laptop-poster.png" alt="laptop-poster"/>
      )}
      </div>
    );
}

export default Poster;