import React from "react";
import "./seventh_page_intro.css";

function SeventhPageIntro(props) {
  return (
    <div className="seventh-page-intro">
      <div className="introduction-section">
        <p>
          There are various ways to become part of this website, such as joining
          as a developer in the company, contributing informative content
          related to pets as a regular user, or listing your pet-related
          business such as a veterinary clinic or pet shop. This website caters
          to true pet lovers, and there are multiple avenues to get involved.
        </p>
      </div>
      <div className="logo-section">
        <img src="images/logo/pet-diary.png" alt="logo" />
        <div className="share-button-section">
          <button type="button">
            <img src="images/buttons/twitter-button.png" alt="twitter" />
          </button>
          <button type="button">
            <img src="images/buttons/facebook-button.png" alt="facebook" />
          </button>
          <button type="button">
            <img src="images/buttons/insta-button.png" alt="instagram" />
          </button>
        </div>
      </div>
      <div className="button-section">
        <button type="button">Sign Up</button>
        <button type="button" onClick={props.showcomingsoon}>Join as a Developer</button>
        <button type="button" onClick={props.showcomingsoon}>List Your Business</button>
      </div>
    </div>
  );
}

export default SeventhPageIntro;
