import React from "react";
import "./graphical-pet-info.css";

function ComingSoon(){
  return (
    <div className="comming-soon" style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
      color: "#fff8f3",
      background:"#6d123f",
      fontFamily: "Poppins",
      fontSize: "1.5rem",
    
    }}>
      <h1 style={{
        fontWeight: "bold",
      }}>Coming Soon</h1>
      <p style={{
        textAlign: "center",
      }}>Stay tuned for more updates!</p>
    </div>
  );
}

function GraphicalPetInfo() {
  return (
    <ComingSoon />
  );
  // return (
  //   <div className="graphical-info-container">
  //     <div className="right-side-details">
  //       <div className="detail">
  //         <p>Age:</p>
  //         <p>12yr</p>
  //       </div>
  //       <div className="detail">
  //         <p>Age:</p>
  //         <p>12yr</p>
  //       </div>
  //       <div className="detail">
  //         <p>Age:</p>
  //         <p>12yr</p>
  //       </div>
  //     </div>
  //     <div className="pet-img">
  //       <img src="images/first-page/first-img.png" alt="pet-img" />
  //     </div>
  //     <div className="left-side-details">
  //       <div className="detail">
  //         <p>Age:</p>
  //         <p>12yr</p>
  //       </div>
  //       <div className="detail">
  //         <p>Age:</p>
  //         <p>12yr</p>
  //       </div>
  //       <div className="detail">
  //         <p>Age:</p>
  //         <p>12yr</p>
  //       </div>
  //     </div>
     
  //   </div>
  // );
}

export default GraphicalPetInfo;
