import React from "react";
import "./seventh_page_second_part.css";

function SeventhPageSecondPart(props) {
  return (
    <div
      className="seller-description-container"
      style={{ background: props.background }}
    >
      <div className="details">
        <div className="line-style">
          <div style={{background:props.buttonbackground}}></div>
          <div style={{background:props.buttonbackground}}></div>
          <div style={{background:props.buttonbackground}}></div>
          <div style={{background:props.buttonbackground}}></div>
          <div style={{background:props.buttonbackground}}></div>
        </div>
        <div className="description">
          {props.shopdetails.map((detail, index) => (
            <p key={index} style={{color:props.buttonbackground}}>{detail}</p>
          ))}
        </div>
      </div>
      <div className="logo">
        <img src="images/logo/pet-diary.png" alt="logo" />
        <div className="share-button-container">
          <button type="button">
            <img src="images/buttons/twitter-button.png" alt="twitter" />
          </button>
          <button type="button">
            <img src="images/buttons/facebook-button.png" alt="facebook" />
          </button>
          <button type="button">
            <img src="images/buttons/insta-button.png" alt="instagram" />
          </button>
        </div>
      </div>
      <button
        type="button"
        className="join-us-button"
        style={{ background: props.buttonbackground, color: props.background }}
        onClick={props.showcomingsoon}
      >
        {props.buttonname}
      </button>
    </div>
  );
}

export default SeventhPageSecondPart;
