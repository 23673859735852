import React, { useState } from "react";
import "./post.css";

function Post(props) {
  const [posts, updatePosts] = useState({
    post: "",
    imgUrl: "",
  });
  const [showUploadimg, updateShowUploadimg] = useState(false);
  const submit = async (event) => {
    event.preventDefault();
    updateShowUploadimg(true);
    const formData = new FormData();
    formData.append("post", posts.post);
    if (posts.imgUrl === "" || posts.imgUrl === undefined) {
      formData.append("files", null);
    } else {
      posts.imgUrl.forEach((img) => {
        formData.append("files", img.imgFile);
      });
    }
    const response = await fetch("https://getapi.petdiary.in/compose", {
      method: "POST",
      credentials: "include",
      body: formData,
    });
    const result = await response.json();
    if (result.status === "success") {
      alert("Post added successfully");
      updatePosts({
        post: "",
        imgUrl: "",
      });
      window.location="/";
    }
  };
  return (
    <div
      className="post-container"
      style={
        props.addpost
          ? window.innerWidth > 768
            ? {
                width: "50%",
                left: "25%",
                display: "flex",
                justifyContent: "center",
                textAlign: "right",
                height: "70vh",
              }
            : {
                width: "90%",
                left: "5%",
                display: "flex",
                justifyContent: "center",
                textAlign: "right",
                height: "70vh",
              }
          : { right: "5%" }
      }
    >
      {props.addpost ? (
        <div className="post-section">
          <button type="button" onClick={() => props.updateAddPost(false)}>
            <img src="images/buttons/wrong.png" alt="close" />
          </button>
          <form onSubmit={submit}>
            <textarea
              placeholder="What's on your mind?"
              autoFocus={true}
              value={posts.post}
              onChange={(event) => {
                updatePosts({
                  ...posts,
                  post: event.target.value,
                });
              }}
            ></textarea>
            <div className="image-upload-section">
              <div className="image-section">
                {posts.imgUrl === undefined || posts.imgUrl === ""
                  ? ""
                  : posts.imgUrl.map((img, index) => (
                      <img
                        key={index}
                        id={index}
                        src={img.imgUrl}
                        alt="uploads"
                        onClick={(event) => {
                          let temp = posts.imgUrl;
                          temp.splice(event.target.id, 1);
                          updatePosts({
                            ...posts,
                            imgUrl: temp,
                          });
                        }}
                      />
                    ))}
              </div>
              <label htmlFor="file-upload" className="custom-file-upload">
                <img src="images/buttons/insert-image.png" alt="upload" />
                <span>Upload Photos</span>
              </label>
              <input
                id="file-upload"
                type="file"
                onChange={(event) => {
                  if(event.target.files[0].type.split("/")[0]!=="image"){
                    alert("Please upload an image file 👏");
                    window.location.reload();
                    return;
                  }
                  else{
                  updatePosts({
                    ...posts,
                    imgUrl: [
                      ...posts.imgUrl,
                      {
                        imgUrl: URL.createObjectURL(event.target.files[0]),
                        imgFile: event.target.files[0],
                      },
                    ],
                  });
                  }
                }}
              />
            </div>
            {showUploadimg ? (
              <div className="loading">
                <img style={{ width: "100px" }}
                  src="https://media.giphy.com/media/RghKaYtGPjghvQ98zA/giphy.gif?cid=790b7611ttx5g1n8tfw6eb5uqukly90y4ge7rz0w5rr7o218&ep=v1_stickers_search&rid=giphy.gif&ct=s"
                  alt="loading"
                />
              </div>
            ) : (
              ""
            )}
            <button type="submit" disabled={showUploadimg}>Post</button>
          </form>
        </div>
      ) : (
        <button
          type="button"
          className="show-post"
          onClick={() => props.updateAddPost(true)}
        >
          <img src="images/buttons/add-post.png" alt="add-post" />
          <span>Post</span>
        </button>
      )}
    </div>
  );
}

export default Post;
