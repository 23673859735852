import React from "react";
import "./welcome-section.css";
import CurveImage from "./CurveImage";

function WelcomeSection(props) {
  return (
    <div
      className="welcome-section"
      id="welcome-section"
      style={props.opaquebackground ? { opacity: "0.5" } : undefined}
    >
      <div className="img-section">
        <CurveImage source="images/first-page/first-img.png" />
        <CurveImage source="images/first-page/second-img.png" />
        <CurveImage source="images/first-page/third-img.png" />
      </div>
      <div className="info-section">
        <h3>
          Welcome <br />
          to
        </h3>
        <p>PET DIARY</p>
        <div className="info-button">
          <button
            onClick={() => {
              document.getElementById("information").scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            <img src="images/first-page/arrow-img.png" alt="" />
          </button>
        </div>
      </div>
      <div
        style={{
          height: "1px",
          background: "#FFF8F3",
          borderTop: "3px solid #6d123f",
          gridColumn: "span 2",
        }}
      ></div>
    </div>
  );
}

export default WelcomeSection;
