import React from "react";
import "./fifth_page_intro.css";


function FifthPageIntroSection(props) {
  return (
    <div className="fifth_page_intro_section">
      <div style={{width:"90%"}}>
        <p>
          Welcome to our virtual sanctuary, dedicated to pet lovers who hold
          their furry companions dear. Our platform is designed to offer a
          plethora of services, ensuring that your pet's every need is met with
          ease.
        </p>
      </div>
      <div className="img-section">
        <img src="images/fifth-page/first.png" alt="first"/>
        <img src="images/fifth-page/second.png" alt="second" style={{borderRadius:"100%"}}/>
        <img src="images/fifth-page/third.png" alt="third" style={{borderRadius:"100%"}}/>
        <img src="images/fifth-page/fourth.png" alt="fourth"/>
      </div>
      <div className="button-section">
        <button onClick={()=>props.showseventhpage()}>Become a part</button>
        <button onClick={()=>props.showfifthpage()}>Explore Us</button>
      </div>
    </div>
  );
}

export default FifthPageIntroSection;
