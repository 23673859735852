import React, { useState } from "react";
import "./comment_box.css";

function CommentBox(props) {
  const [comment, updateComment] = useState("");
   const [showloading, updateLoading] = useState(false);
  const postComment = (event) => {
    event.preventDefault();
     updateLoading(true);
    const id = event.target.id;
    fetch("https://getapi.petdiary.in/post/comments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        postId: id,
        comment: comment,
      })
    }).then((res) => {
      if (res.status === 200) {
        updateComment("");
        alert("Comment posted successfully");
        window.location = "/";
      } else {
        alert("Failed to post comment");
      }
       updateLoading(false);
    });
  };
  return (
    <div className="comment-box">
      <button
        className="close-button"
        type="button"
        onClick={() => props.close(!true)}
      >
        {" "}
        <img src="images/buttons/wrong.png" alt="wrong" />
      </button>
      {props.comments.length === 0
        ? undefined
        : props.comments.map((comment, index) => (
            <div className="comments" key={index}>
              <div className="profile">
                <img src={comment.user.profilePic} alt="logo" />
                <h3>{comment.user.name}</h3>
              </div>
              <div className="comment">
                <p>{comment.comment}</p>
              </div>
            </div>
          ))}

      <div className="input-comment">
        <form id={props.id} onSubmit={postComment}>
          <textarea
            placeholder="Your comment.."
            value={comment}
            onChange={(event) => {
              updateComment(event.target.value);
            }}
          />
          <button type="submit" disabled={showloading}>
            <img src="images/buttons/submit.png" alt="submit" />
          </button>
        </form>
      </div>
    </div>
  );
}

export default CommentBox;
