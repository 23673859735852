import React from "react";
import WelcomeSection from "./WelcomeSection";
import Information from "./Information";
import TrendingDiaries from "./TrendingDiaries";

function FirstPage(props) {
  const showsixthpage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    props.updateFirstPage(false);
    props.updateSixthPage(true);
  };
  const showfifthpage = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    props.updateFirstPage(false);
    props.updateFifthPage(true);
  };
  return (
    <div>
      <WelcomeSection
        opaquebackground={props.showsinglediary || props.showintroductionvideo}
      />
      <Information
        opaquebackground={props.showsinglediary || props.showintroductionvideo}
        details={[
          "Store Pet Information Securely.",
          "Get regular updates.",
          "Connects With Same Minded People.",
          "Access Doorstep Services.",
        ]}
        imgsources={[
          "images/first-page/fourth-img.png",
          "images/first-page/fifth-img.png",
          "images/first-page/sixth-img.png",
        ]}
        buttons={[
          { name: "Know Us", functionality: showsixthpage },
          { name: "Explore Us", functionality: showfifthpage },
        ]}
      />
      <TrendingDiaries
        updateDiaryState={props.updateDiaryState}
        isUserAuthenticated={props.isUserAuthenticated}
        updateSignUp={props.updateSignUp}
        latestposts={props.latestposts}
        showsinglediary={props.showsinglediary}
        updateSingleDiaryState={props.updateSingleDiaryState}
        likedPosts={props.userData === "" ? false : props.userData.likedPosts}
      />
    </div>
  );
}

export default FirstPage;
