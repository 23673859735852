import React from "react";
import "./introduction-video.css";

function IntroductionVideo(props) {
  return (
    <div className="introduction-video">
      <div className="cut-button">
        <button
          onClick={() =>
            props.updateIntroductionVideo(!props.showintroductionvideo)
          }
        >
          <img src="images/buttons/wrong.png" alt="cross" />
        </button>
      </div>
      <div className="video-section">
        <video muted={true} autoPlay={true} controls={true}>
          <source src="/videos/introduction.mp4" type="video/mp4" />
        </video>
      </div>
      <div className="introduction-section">
        <p>
          <span>We</span> are Pet diary and our main motto is to help you in
          every step of taking care of your pet from providing all your services
          at your doorstep to connecting people from all over the world having
          the same interest.
        </p>
      </div>
      <div className="button-section">
        <button
          type="button"
          onClick={() => {
            props.updateIntroductionVideo(false);
            props.updateFirstPage(false);
            props.updateFifthPage(true);
          }}
        >
          Explore Us
        </button>
        <button
          type="button"
          onClick={() => {
            props.updateIntroductionVideo(false);
            props.updateSignUp(true);
          }}
        >
          Sign Up
        </button>
        <button
          type="button"
          onClick={() => {
            props.updateIntroductionVideo(false);
            props.updateFifthPage(false);
            props.updateSixthPage(true);
          }}
        >
          About Us
        </button>
      </div>
    </div>
  );
}

export default IntroductionVideo;
